import React, { useContext } from "react";
import Link from "next/link";
import { usePathname, useRouter } from "next/navigation";
import { UserContext } from "../utils/user";
import { User } from "../types";

interface Tab {
  label: string;
  path: string;
  displayCondition: (user: User) => boolean;
}

const Tabs: React.FC<{
  tabs: Tab[];
}> = ({ tabs }) => {
  const routerPath = usePathname() as string;
  const segments = routerPath.split("/");
  const route = segments[1];
  const router = useRouter();
  const user = useContext(UserContext).user;
  if (!user) {
    router.push("/login");
  }

  let filteredTabs: Tab[] = [];
  if (user) {
    filteredTabs = tabs.filter((tab) => tab.displayCondition(user));
  }

  const isTabHighlighted = (route: string, tab: Tab) => {
    return routerPath.startsWith(tab.path);
    // if (tab.path.includes("company") && tab.path.includes("advertisement")) {
    //   return segments[3] === "advertisement";
    // } else if (
    //   tab.path.includes("company") &&
    //   !tab.path.includes("advertisement")
    // ) {
    //   return segments[3] === "companyinformation";
    // } else if (route?.length && tab.path.includes(route)) {
    //   return true;
    // } else {
    //   return false;
    // }
  };

  return (
    <div className="flex">
      {/* <a
        href="/"
        className={`border-b-2 mr-3 pt-2 ${
          routerPath === "/" ? "border-red-500" : "border-transparent"
        }`}
      >
        <img src="/logo.svg" alt="MTI" className="ml-3 " />
      </a> */}
      {filteredTabs.map((tab) => (
        <Link key={tab.path} href={tab.path}>
          <div
            className={`text-center border-b-2 px-2 h-16 pt-5 ${
              isTabHighlighted(route, tab)
                ? "border-red-500"
                : "border-transparent"
            }`}
          >
            {tab.label}
          </div>
        </Link>
      ))}
    </div>
  );
};

export default Tabs;
