import { User, role } from "@/app/types";

export interface NavbarTab {
  label: string;
  path: string;
  displayCondition: (user: User) => boolean;
}

export const navbarTabs: NavbarTab[] = [
  {
    label: "My Company",
    path: "/company/information",
    displayCondition: (user: User) => user?.companyId != null,
  },
  {
    label: "Open Rounds",
    path: "/open-rounds",
    displayCondition: (user: User) =>
      user?.openRoundsAccess || user?.role == role.admin,
  },
  {
    label: "Open Round",
    path: "/open-round",
    displayCondition: (user: User) =>
      user?.company?.cohortMembership?.length > 0,
  },
  {
    label: "Portfolio Directory",
    path: "/portfolio-directory",
    displayCondition: (user: User) =>
      user?.company?.cohortMembership?.length > 0 ||
      (user?.role && [role.admin, role.partner].includes(user.role)),
  },
  {
    label: "Applications",
    path: "/applications",
    // displayCondition: (user: User) => user?.role == role.company,
    displayCondition: (user: User) =>
      `${process.env.NODE_ENV}` === "development",
  },
  // {
  //   label: "Investor Directory",
  //   path: "/investor-directory",
  //   displayCondition: (user: User) =>
  //     user?.company?.cohortMembership?.length > 0 &&
  //     `${process.env.NODE_ENV}` === "development",
  // },
  {
    label: "Forum Discussions",
    path: "/forum/general",
    displayCondition: (user: User) =>
      user?.company?.cohortMembership?.length > 0 ||
      (user?.role && [role.admin, role.partner].includes(user.role)),
  },
];
